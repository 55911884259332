<template>
  <div>
    <transaccion-view
      ref="refTransaccionView"
    />
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              id="empresa"
              v-model="empresaSel"
              label="nombreComercial"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="empresas"
              placeholder="Filtre por Empresa"
              @input="checkedEmpresa"
            />
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="FilterIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <flat-pickr
                v-model="fechaSel"
                class="form-control"
                :config="config"
                placeholder="Filtre por fecha"
                @on-change="checkedFecha"
              />

              <b-input-group-append>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.top="'Quitar filtro'"
                  data-clear
                  variant="outline-primary"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="query"
                class="d-inline-block mr-1"
                placeholder="Buscar por nombre de turista..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
            <strong class="ml-1">Cargando...</strong>
          </div>
        </template>
        <template #cell(fechaPedido)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              <feather-icon
                icon="CalendarIcon"
                size="16"
                class="mr-50"
              />
              {{ data.item.fechaPedido }}
            </span>
          </b-media>
        </template>

        <template #cell(datosEmpresa)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-img
                v-if="data.item.datosEmpresa.multimedia.length > 0"
                :src="`${pathMultimedia}/byUrl?isthumb=true&url=${data.item.datosEmpresa.multimedia[0].url}`"
                width="100"
                rounded
                alt="Display Empresa"
                class="d-inline-block"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.datosEmpresa.nombreComercial }}
            </span>
            <small class="text-muted d-block ">{{ data.item.datosEmpresa.tipoServicioTuristico.labelFrontend }}</small>
          </b-media>
        </template>

        <template #cell(usuario)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-img
                v-if="data.item.usuario.length > 0"
                :src="`${data.item.usuario.display}`"
                width="100"
                rounded
                alt="Display turista"
                class="d-inline-block"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.usuario.nombres }} {{ data.item.usuario.apellidos }}
            </span>
            <small class="text-muted d-block ">
              <feather-icon
                icon="MailIcon"
                size="16"
                class="mr-75"
              />
              <span class="align-middle">{{ data.item.usuario.email }}</span>
            </small>
          </b-media>
        </template>

        <template #cell(transaccion)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              S/. {{ data.item.transaccion[0].amount }}
            </span>
            <small class="text-muted d-block ">{{ data.item.transaccion[0].brand.toUpperCase() }}</small>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(acciones)="data">
          <b-button-toolbar justify>
            <b-button-group size="sm">
              <b-button
                v-b-tooltip.hover.top="'Ver Detalle'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="info"
                @click="showDetails(data)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="page"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BMedia, BSpinner, BImg, BButtonToolbar, BButtonGroup, VBTooltip, BInputGroup, BInputGroupPrepend, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import config from '@/services/config'
import flatPickr from 'vue-flatpickr-component'
import TransaccionView from './TransaccionView.vue'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BMedia,
    BImg,
    BButtonToolbar,
    BButtonGroup,
    flatPickr,

    vSelect,
    BSpinner,
    TransaccionView,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  data: () => ({
    isBusy: false,
    isToggleSidebar: false,
    dataEdit: {},
    formType: '',
    page: 1,
    perPage: 10,
    totalRows: 0,
    totalItems: 0,
    totalPage: 0,
    from: 0,
    to: 0,
    of: 0,
    pageOptions: [10, 20, 50],
    sortBy: null,
    sortDesc: false,
    sortDirection: 'asc',
    query: '',
    seccion: '',
    items: [],
    fields: [
      { key: 'idPedido', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'fechaPedido', label: 'Fecha', sortable: true },
      { key: 'datosEmpresa', label: 'Empresa', sortable: true },
      { key: 'usuario', label: 'Turista', sortable: true },
      { key: 'transaccion', label: 'Transaccion', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'acciones', tdClass: ['w100', 'text-center'], thClass: 'text-center' },
    ],
    dataInfo: {},
    pathMultimedia: `${config.pathRepo}${config.contextBO}/api/v1/multimedia`,
    showDetail: false,
    empresas: [],
    empresaSel: null,
    config: {
      wrap: true,
      enableTime: false,
      dateFormat: 'd/m/Y',
      altInput: true,
      altFormat: 'd/m/Y',
    },
    fechaSel: null,
  }),
  watch: {
    perPage: {
      handler() {
        this.listarRegistros()
      },
    },
    page: {
      handler() {
        this.listarRegistros()
      },
    },
    query: {
      handler() {
        this.listarRegistros()
      },
    },
    seccionSel: {
      handler() {
        this.listarRegistros()
      },
    },
  },
  async mounted() {
    await this.listarRegistros()
    await this.getEmpresas()
  },
  methods: {
    avatarText,
    async getEmpresas() {
      await store
        .dispatch('plataforma/EMPRESA_FIND_ALL', {
          query: '',
          page: 1,
          limit: 10000,
          sortBy: 'nombreComercial%7CASC',
          idDestino: -1,
          idTipoDirectorio: -1,
          urlSlugDirectorio: '',
        })
        .then(response => {
          if (response.items) {
            this.empresas = response.items
          }
        })
        .catch(error => {
          this.$bvToast.toast(error, {
            title: 'Ha ocurrido un error',
            variant: 'danger',
            solid: true,
          })
        })
    },
    async listarRegistros() {
      this.isBusy = true
      await store
        .dispatch('comercial/TRANSACCION_FIND_ALL', {
          idUsuario: -1,
          idEmpresa: this.empresaSel && this.empresaSel.idDirectorio ? this.empresaSel.idDirectorio : -1,
          fecha: this.fechaSel,
          limit: this.perPage,
          query: this.query,
          page: this.page,
          sortBy: 'idPedido%7CDESC',
        })
        .then(response => {
          this.items = response.items
          this.totalRows = response.totalRows
          this.totalItems = response.totalItems
          this.totalPage = response.totalPage
          this.from = this.perPage * (this.page - 1) + (this.totalRows ? 1 : 0)
          this.to = this.perPage * (this.page - 1) + this.totalRows
          this.of = this.totalItems
        })
        .catch(error => {
          this.$bvToast.toast(error, {
            title: 'Ha ocurrido un error',
            variant: 'danger',
            solid: true,
          })
        })
      this.isBusy = false
    },
    async checkedFecha(data) {
      if (data?.length === 0) {
        this.fechaSel = null
      }
      await this.listarRegistros()
    },
    async checkedEmpresa() {
      await this.listarRegistros()
    },
    showDetails(data) {
      this.$refs.refTransaccionView.loadView(data.item)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;
  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }
  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
.w100{
  width: 100px !important;
}
.badge{
  font-size: 75% !important;
}
#seccion-content {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#empresa{
  width: 100% !important;
}
</style>
