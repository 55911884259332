<template>
  <div>
    <b-modal
      v-if="modalShow"
      v-model="modalShow"
      :title="titleModal"
      :hide-footer="true"
      no-close-on-backdrop
      no-close-on-esc
      size="xl"
      :busy="isBusy"
      :ok-disabled="true"
    >
      <b-row cols="12">
        <b-col
          cols="12"
          md="8"
        >
          <b-card
            class="card-transaction"
            no-body
          >
            <b-card-header>
              <b-card-title>Ítems</b-card-title>
            </b-card-header>

            <b-card-body>
              <div
                v-for="detallePedido in detallePedidos"
                :key="detallePedido.idDetallePedido"
                class="transaction-item"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-img
                      :src="getImageProduct(detallePedido.producto.imagenes)"
                      width="100"
                      rounded
                      alt="Banner Noticia"
                      class="d-inline-block"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ detallePedido.producto.nombre }}
                    </h6>
                    <small>Cantidad: {{ detallePedido.cantidad }} - Precio: S/.{{ detallePedido.precioUnitario }}.00</small>
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-success"
                >
                  S/. {{ detallePedido.precioTotal }}.00
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-card
            no-body
            class="card-developer-meetup"
          >
            <b-card-body>
              <!-- metting header -->
              <div class="meetup-header d-flex align-items-center">
                <div class="meetup-day">
                  <b-avatar
                    rounded
                    size="42"
                    :src="`${pathMultimedia}/byUrl?isthumb=true&url=${empresa.multimedia[0].url}`"
                  />
                </div>
                <div class="my-auto">
                  <b-card-title class="mb-25">
                    {{ empresa.nombreComercial }}
                  </b-card-title>
                  <b-card-text class="mb-0">
                    {{ empresa.tipoServicioTuristico.labelFrontend }}
                  </b-card-text>
                </div>
              </div>
              <!--/ metting header -->

              <!-- media -->
              <b-media
                key="ped-phone"
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="PhoneIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ empresa.celular }}
                  </h6>
                  <small>Celular</small>
                </b-media-body>
              </b-media>

              <b-media
                key="ped-email"
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MailIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ empresa.email }}
                  </h6>
                  <small>Correo</small>
                </b-media-body>
              </b-media>

            </b-card-body>
          </b-card>
          <b-card
            no-body
            class="card-developer-meetup"
          >
            <b-card-body>
              <!-- metting header -->
              <div class="meetup-header d-flex align-items-center">
                <div class="meetup-day">
                  <b-avatar
                    rounded
                    size="42"
                    :src="turista.display"
                  />
                </div>
                <div class="my-auto">
                  <b-card-title class="mb-25">
                    {{ turista.nombres }} {{ turista.apellidos }}
                  </b-card-title>
                  <b-card-text class="mb-0">
                    Inició sesión desde {{ turista.tipoLogin }}
                  </b-card-text>
                </div>
              </div>
              <!--/ metting header -->

              <!-- media -->
              <b-media
                key="ped-phone"
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-warning"
                    size="34"
                  >
                    <feather-icon
                      icon="PhoneIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ turista.celular }}
                  </h6>
                  <small>Celular</small>
                </b-media-body>
              </b-media>

              <b-media
                key="ped-email"
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-warning"
                    size="34"
                  >
                    <feather-icon
                      icon="MailIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ turista.email }}
                  </h6>
                  <small>Correo</small>
                </b-media-body>
              </b-media>

            </b-card-body>
          </b-card>
          <b-card
            class="card-transaction"
            no-body
          >
            <b-card-header>
              <b-card-title>Transacción</b-card-title>
            </b-card-header>

            <b-card-body>
              <div
                class="transaction-item"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      variant="light-success"
                    >
                      <feather-icon
                        size="18"
                        icon="CreditCardIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ transaccion.card }}
                    </h6>
                    <small>{{ transaccion.brand.toUpperCase() }}</small>
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-success"
                >
                  S/.{{ transaccion.amount }}
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import config from '@/services/config'
import { ref } from '@vue/composition-api'
import {
  BModal, BCardBody, BImg, BCardHeader, BCardTitle, BMediaBody, BMedia, BMediaAside, BRow, BCol, BCard, BAvatar, BCardText,
} from 'bootstrap-vue'
// import { useNotify } from '@/helpers/toast'
// import { usePopover } from '@/helpers/popover'

export default {
  components: {
    BAvatar,
    BModal,
    BCard,
    BCardBody,
    BImg,

    BCardHeader,
    BCardTitle,
    BCardText,
    BMediaBody,
    BMedia,
    BMediaAside,
    BRow,
    BCol,
  },
  setup() {
    const modalShow = ref(false)
    const isBusy = ref(false)
    const titleModal = ref('')
    const { pathRepo } = config
    const pathMultimedia = ref(`${pathRepo}${config.contextBO}/api/v1/multimedia`)

    const detallePedidos = ref([])
    const empresa = ref({})
    const turista = ref({})
    const pedido = ref({})
    const transaccion = ref({})

    const loadView = data => {
      titleModal.value = `Pedido N° 0000${data.idPedido}  |  Registrado: ${data.fechaPedido}`
      pedido.value = {
        fechaPedido: data.fechaPedido,
        estadoPedido: data.estadoPedido,
        referenciaPago: data.referenciaPago,
      }
      detallePedidos.value = data.detalle
      empresa.value = data.datosEmpresa
      turista.value = data.usuario
      // eslint-disable-next-line prefer-destructuring
      transaccion.value = data.transaccion[0]
      modalShow.value = true
    }

    const getImageProduct = imgs => {
      const imgsArr = imgs.split('***')
      return `${pathMultimedia.value}/byUrl?isthumb=true&url=${imgsArr[0]}`
    }

    return {
      modalShow,
      isBusy,
      titleModal,
      loadView,
      getImageProduct,
      pathMultimedia,

      pedido,
      detallePedidos,
      empresa,
      turista,
      transaccion,
    }
  },
}
</script>
